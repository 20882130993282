import { getOrCreateDataObject } from 'o365-dataobject';

export function createWhereClause(doNotShowList: Array<string>){
    let whereClauseArr: Array<string>  = [];
    let whereClause = "";
    if(doNotShowList.length > 0){
        doNotShowList.forEach((x:string)=>{
            whereClauseArr.push(`NodeType <> '${x}'`)
        })
    }
    whereClause = whereClauseArr.join(' AND ');

    return whereClause
}

export function formFilterString(filterObject: Array<{type: string; bool: boolean }>){
    dsTree.recordSource.filterString = '';
    let filterStringArr: Array<string> = [];

    filterObject.forEach((x)=>{
        if(x.bool){
            filterStringArr.push(`'${x.type}'`)
        }
    })
    if(filterStringArr.length > 0){

        let filterString = filterStringArr.join(', ');
        dsTree.recordSource.filterString =`[NodeType] IN (${filterString})`;
        dsTree.load();
    }
    else{
        dsTree.recordSource.filterString = "";
        dsTree.load();
    }

}

export const dsTree = getOrCreateDataObject(
    {
    "id": "dsWBSTree"+ "_" + crypto.randomUUID(),
    "viewName": "aviw_Cost_WBSTreeNT",
    "distinctRows": false,
    "maxRecords": 1000,
    "fields": [
            {"name": "PrimKey",},
            {"name": "ID",},
            {"name": "OrgUnit_ID",},
            {"name": "OrgUnitIdPath", "sortOrder": 2,"sortDirection": "asc"},
            {"name": "SortOrder","sortOrder": 5,"sortDirection": "asc",},
            {"name": "AccessIdPath",},
            {"name": "NodeIdPath","sortOrder": 3,"sortDirection": "asc",},
            {"name": "WBSIdPath"},
            {"name": "Name",},
            {"name": "Title",},
            {"name": "NameAndTitle",},
            {"name": "Parent_ID",},
            {"name": "NamePath","sortOrder": 4,"sortDirection": "asc",},
            {"name": "NodeLevel",},
            {"name": "NodeType",},
            {"name": "HasNodes",},
            {"name": "IsClosed",},
            {"name": "IsClosedOrgUnit",},
            {"name": "Canceled",},
            {"name": "IsFinished",}
        ]
    }
);


export function getTreeDataSource(whereClause: string, selectFirstRowOnLoad: boolean){
    dsTree.recordSource.whereClause = whereClause;
    dsTree.selectFirstRowOnLoad = selectFirstRowOnLoad
    return dsTree;
}

 export async function setTreeCurrentIndex(id:number, isOrgunit: boolean = false){
    let node
    if(isOrgunit){
        node = dsTree.nodeData.findNode((x)=> x.ID == id && x.OrgUnit_ID == id);
    }else{
        node = dsTree.nodeData.findNode((x)=> x.ID == id);
    }

    if ( node !== null && node?.isLoading  ) {
        await node.loadingPromise;
    };

    node?.expandTo();
    dsTree.setCurrentIndex(node?.index);
}